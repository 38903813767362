import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
    Drawer,
    List,
    ListItemIcon,
    ListItemText,
    IconButton,
    Collapse,
    ListItemButton,
    Box,
    Typography,
    Stack,
    Toolbar,
} from "@mui/material";
import Axios from "axios";

import CheckShow, { CheckShowBool } from "./shared/CheckShow";
import LoadingBubble from "./LoadingBubble";

import version from "../version";

import Colours from "../helpers/colours";
import Icons from "../helpers/icons";
import { ARO_ADMIN, ARO_ALL, URLAPI } from "../configuration";

import { dataSimpleTypes } from "../types/dataSimpleTypes";
import { dataVouchersDetailsTypes } from "../types/dataVouchersDetailsTypes";
import { dataSearchesTypes } from "../types/dataSearchesTypes";
import { dataMembershipTypes } from "../types/dataMembershipTypes";
import { dataBookingsTypes } from "../types/dataBookingsTypes";
import { dataCommissionTypes } from "../types/dataCommissionTypes";
import { useSelector } from "react-redux";

const OPENED_SIZE = 300;
const CLOSED_SIZE = 20;

const AUTO_OPEN_ZONE = 20;

const BUTTON_SIZE = 30;

const SideMenu = ({ startOpened = true, closeOnLeave = false }) => {
    /**
     * ELEMENTS array defines sidebar navigation structure, comprising. It is possible to use CheckHide and CheckShow with them:
     * - title (String): The display name of the navigation item.
     * - base_url (String): The base URL or route for the navigation item.
     * - icon (Component): An icon component for visual representation.
     * - exact_url (String, optional): An exact route match to highlight the item as active.
     * - subs (Array, optional): Sub-items for nested navigation, each with:
     *   - title (String): Display name of the sub-item.
     *   - url (String or Array): URL or an array of URLs for the sub-item, used for matching and navigation.
     *   - exact_url (String, optional): An exact route for precise active state matching.
     */
    const ELEMENTS = [
        {
            title: "Reporting",
            base_url: "/app/report",
            icon: Icons.Reports,
        },
        {
            title: "Events",
            base_url: "/app/plus/events",
            icon: Icons.Calendar,
        },
        CheckShow({
            rolesThatCanSee: ARO_ALL,
            children: {
                title: "Insights",
                icon: Icons.Insights,
                base_url: "/app/insights",
            },
        }),
        CheckShow({
            k: "Membership",
            children: {
                title: "Membership",
                icon: Icons.Details,
                base_url: "/app/report/membership",
            },
        }),
        CheckShow({
            rolesThatCanSee: ARO_ALL,
            children: {
                title: "Commission Report",
                icon: Icons.Details,
                base_url: "/app/commission",
            },
        }),
        CheckShow({
            rolesThatCanSee: ARO_ALL,
            children: {
                title: "Account Managers",
                // base_url: "/app/report",
                //icon: Icons.AccountManagers,
                subs: [
                    {
                        title: "Saved resources",
                        url: "/savedresources",
                        icon: Icons.AccountManagers,
                    },
                    {
                        title: "Indicators",
                        url: "/app/plus/indicators/",
                        icon: Icons.InsightExpert,
                    },
                    {
                        title: "Indicators2",
                        url: "/indicators2",
                        icon: Icons.InsightExpert,
                    },
                    {
                        title: "Red to greens",
                        url: "/app/plus/red2greens",
                        icon: Icons.StatusPartialComplete,
                    },
                    {
                        title: "Targets",
                        url: "/targets",
                        icon: Icons.LineChartScale,
                    },
                    {
                        title: "Hotel email list",
                        url: "/email",
                        icon: Icons.Email,
                    },
                    {
                        title: "Email logs",
                        url: "/logs",
                        icon: Icons.Bug,
                    },
                ],
            },
        }),

        CheckShow({
            rolesThatCanSee: ARO_ALL,
            children: {
                title: "Admin",
                // icon: Icons.AdminPanel,
                roles: [...ARO_ALL],
                subs: [
                    {
                        title: "Settings",
                        url: "/settings",
                        icon: Icons.Settings,
                    },
                    {
                        title: "Icons and colours",
                        url: "/icons",
                        icon: Icons.Emoji,
                    },
                    {
                        title: "Hotel identifier",
                        url: "/hotels",
                        icon: Icons.Hotel,
                    },
                    {
                        title: "User profile",
                        url: "/profile",
                        icon: Icons.UserProfile,
                    },
                    {
                        title: "Sales summary",
                        url: "/app/plus/sales",
                        icon: Icons.LineChart3,
                    },
                    {
                        title: "AI",
                        url: "/app/plus/ai",
                        icon: Icons.LineChart2,
                    },
                    {
                        title: "Events Impact",
                        url: "/eventsimpact",
                        icon: Icons.LineChart,
                    },
                ],
            },
        }),

        // Old version
        //      _          __    ____                       _
        //     / \   _ __ /_/   |  _ \ ___ _ __   ___  _ __| |_
        //    / _ \ | '__/ _ \  | |_) / _ \ '_ \ / _ \| '__| __|
        //   / ___ \| | | (_) | |  _ <  __/ |_) | (_) | |  | |_
        //  /_/   \_\_|  \___/  |_| \_\___| .__/ \___/|_|   \__|
        //                                |_|
        /*
        {
            title: "Aró Report",
            base_url: "/app/report",
            icon: Icons.Reports,
            exact_url: "/app",
            subs: [
                {
                    title: "Performance",
                    url: "/app/report/dashboard",
                    exact_url: "/app",
                },
                // { title: "Monthly Summary", url: "/app/report/summary" },
                { title: "Monthly KPIs", url: "/app/report/kpis" },
                CheckShow({
                    k: "Membership",
                    children: {
                        title: "Membership",
                        url: "/app/report/membership",
                    },
                }),
            ],
        },
        //      _          __    ___           _       _     _
        //     / \   _ __ /_/   |_ _|_ __  ___(_) __ _| |__ | |_ ___
        //    / _ \ | '__/ _ \   | || '_ \/ __| |/ _` | '_ \| __/ __|
        //   / ___ \| | | (_) |  | || | | \__ \ | (_| | | | | |_\__ \
        //  /_/   \_\_|  \___/  |___|_| |_|___/_|\__, |_| |_|\__|___/
        //                                       |___/
        {
            title: "Aró Insights",
            base_url: "/app/insights",
            icon: Icons.Insights,
        },
        //      _          __
        //     / \   _ __ /_/      _
        //    / _ \ | '__/ _ \   _| |_
        //   / ___ \| | | (_) | |_   _|
        //  /_/   \_\_|  \___/    |_|
        {
            title: "Aró +",
            base_url: "/app/plus",
            icon: Icons.PLUS,
            subs: [
                { title: "Sales Summary", url: "/app/plus/sales" },
                { title: "Marketing Strategy", url: "/app/report/marketing" },
                CheckShow({
                    rolesThatCanSee: ARO_ALL,
                    children: {
                        title: "Indicators",
                        url: ["/app/plus/indicators/"],
                    },
                }),
                CheckShow({
                    rolesThatCanSee: ARO_ALL,
                    children: {
                        title: "Red To Greens",
                        url: "/app/plus/red2greens",
                    },
                }),
                { title: "AI", url: "/app/plus/ai" },
            ],
        },
        //      _    ____  __  __ ___ _   _
        //     / \  |  _ \|  \/  |_ _| \ | |
        //    / _ \ | | | | |\/| || ||  \| |
        //   / ___ \| |_| | |  | || || |\  |
        //  /_/   \_\____/|_|  |_|___|_| \_|
        CheckShow({
            rolesThatCanSee: ARO_ALL,
            children: {
                title: "Admin Panel",
                icon: Icons.AdminPanel,
                roles: [...ARO_ALL],
                subs: [
                    { title: "Settings", url: "/settings" },
                    { title: "Targets", url: "/targets" },
                    // { title: "Events Calendar", url: "/eventscalendar" },
                    // { title: "PDF Export", url: "/pdfexport" },
                    { title: "Icons and Colours", url: "/icons" },
                    { title: "Hotel Identifier", url: "/hotels" },
                    { title: "User Profile", url: "/profile" },
                    // { title: "Release Notes", url: "/release" },
                ],
            },
        }),*/
    ];

    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [keepOpen, setKeepOpen] = useState(true);

    const handleMouseMove = (e) => {
        const x = e.clientX;

        if (x <= AUTO_OPEN_ZONE) {
            setOpen(true);
            setKeepOpen(true);
        }
    };

    const [APIversion, setAPIversion] = useState("0.0.0");
    useEffect(() => {
        Axios.get(`${URLAPI}/version`).then((res) => {
            setAPIversion(res.data);
        });
    }, []);

    useEffect(() => {
        if (closeOnLeave) {
            setOpen(false);
            setKeepOpen(false);
        }
    }, [closeOnLeave]);

    return (
        <div
            onMouseMove={handleMouseMove}
            onMouseLeave={() => {
                setOpen(false);
                if (closeOnLeave) setKeepOpen(false);
            }}
            style={{ display: "flex", marginRight: "1em" }}
        >
            <IconButton
                size="small"
                style={{
                    width: BUTTON_SIZE,
                    height: BUTTON_SIZE,
                    position: "fixed",
                    top: "100px",
                    left:
                        open || keepOpen
                            ? `${OPENED_SIZE - BUTTON_SIZE / 2}px`
                            : `${CLOSED_SIZE / 2}px`,
                    backgroundColor: Colours.backgroundWhite,
                    zIndex: 1200,
                    transition: "all 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
                    border: `1px solid ${Colours.navbarBottomLine}`,
                }}
                onClick={() => {
                    if (open || keepOpen) {
                        setKeepOpen(false);
                        setOpen(false);
                    } else {
                        setKeepOpen(true);
                        setOpen(true);
                    }
                }}
            >
                {open || keepOpen ? (
                    <Icons.ArrowLeft fontSize="inherit" />
                ) : (
                    <Icons.ArrowRight fontSize="inherit" />
                )}
            </IconButton>

            <Drawer
                variant="permanent"
                open={open || keepOpen}
                sx={{
                    zIndex: 1199,
                    width: open || keepOpen ? OPENED_SIZE : CLOSED_SIZE,
                    transition: (theme) =>
                        theme.transitions.create("width", {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.enteringScreen,
                        }),
                    "& .MuiDrawer-paper": {
                        background: "#fafafa",
                        borderRight: `1px solid ${Colours.navbarBottomLine}`,
                        justifyContent: "space-between",
                        overflowX: "hidden",
                        width: open || keepOpen ? OPENED_SIZE : CLOSED_SIZE,
                        transition: (theme) =>
                            theme.transitions.create("width", {
                                easing: theme.transitions.easing.sharp,
                                duration:
                                    theme.transitions.duration.leavingScreen,
                            }),
                    },
                }}
            >
                {(open || keepOpen) && (
                    <>
                        <List
                            style={{
                                overflowY: "auto",
                                "::WebkitScrollbar": {
                                    width: "none",
                                    margin: "none",
                                },
                                scrollbarWidth: "none",
                            }}
                        >
                            {/* ↓ This is for reserving some room for the TopBar */}
                            <Toolbar />
                            {ELEMENTS.filter((e) => e).map((e) => (
                                <React.Fragment key={e.title}>
                                    <CollapsibleItemSideMenu
                                        key={e.title}
                                        title={e.title}
                                        Icon={e.icon}
                                        subs={(e.subs ?? []).filter((e) => e)}
                                        url={e.base_url}
                                        setOpenPanel={setOpen}
                                        exact_url={e.exact_url ?? ""}
                                    />
                                    <br />
                                </React.Fragment>
                            ))}
                        </List>
                        {/* Bottom */}
                        <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                p: 2,
                                borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                            }}
                        >
                            <Typography
                                fontSize={"0.75em"}
                                onClick={() => {
                                    caches.keys().then(function (names) {
                                        for (let name of names)
                                            caches.delete(name);
                                        window.location.reload(true);
                                    });
                                }}
                                style={{ cursor: "pointer" }}
                            >
                                Versions: {version()} / v{APIversion}
                            </Typography>

                            {/* <Typography
                                fontSize={"0.6em"}
                                onClick={() => {
                                    navigate("/release");
                                    setOpen(false);
                                }}
                                style={{
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                }}
                            >
                                Release Notes
                            </Typography> */}
                            <Bubbles />
                        </Stack>
                    </>
                )}
            </Drawer>
        </div>
    );
};

const IsSubSelected = (url, path) =>
    Array.isArray(url)
        ? url.includes(path) || url.some((u) => IsSubSelected(u, path))
        : path === url || path.includes(url);

const ItemSideMenu = ({
    title,
    url,
    setOpenPanel = () => {},
    exact_url = "",
    Icon, // = Icons.square
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const firstUrl = Array.isArray(url) ? url[0] : url;
    const isSelected =
        IsSubSelected(url, location.pathname) ||
        exact_url === location.pathname;

    return (
        <ListItemButton
            sx={{ py: 1, pl: "2em" }}
            selected={isSelected}
            onClick={() => {
                navigate(firstUrl);
                setOpenPanel(false);
            }}
        >
            {Icon && (
                <ListItemIcon sx={{ minWidth: 35 }}>
                    <Icon
                        fontSize="small"
                        color={isSelected ? "secondary" : "inherit"}
                    />
                </ListItemIcon>
            )}
            <ListItemText
                primary={title}
                sx={{
                    "& .MuiListItemText-primary": {
                        color: isSelected ? "primary.main" : "inherit",
                    },
                }}
            />
        </ListItemButton>
    );
};

const CollapsibleItemSideMenu = ({
    title,
    Icon, // = Icons.Square,
    url,
    subs,
    setOpenPanel = () => {},
    exact_url = "",
}) => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const [isSelected, setIsSelected] = useState(false);

    useEffect(() => {
        if (location) {
            if (
                location.pathname.includes(url) ||
                subs.some((s) => IsSubSelected(s.url, location.pathname)) ||
                exact_url === location.pathname
            ) {
                setIsSelected(true);
                setOpen(true);
            } else {
                setIsSelected(false);
            }
        }
    }, [location.pathname]);

    const handleArrowClick = (e) => {
        e.stopPropagation();
        setOpen(!open);
    };

    return (
        <>
            <ListItemButton
                onClick={(e) => {
                    if (!subs.length > 0) {
                        navigate(url);
                        setOpenPanel(false);
                    } else handleArrowClick(e);
                }}
            >
                {!subs.length > 0 && Icon && (
                    <ListItemIcon>
                        <Icon color={isSelected ? "secondary" : "inherit"} />
                    </ListItemIcon>
                )}

                {subs.length > 0 && (
                    <ListItemIcon>
                        {open ? (
                            <Icons.ArrowDown onClick={handleArrowClick} />
                        ) : (
                            <Icons.ArrowRight onClick={handleArrowClick} />
                        )}{" "}
                    </ListItemIcon>
                )}
                <ListItemText primary={title} />
            </ListItemButton>
            {subs.length > 0 && (
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List>
                        {subs.map((sub) => (
                            <ItemSideMenu
                                key={sub.title}
                                title={sub.title}
                                url={sub.url}
                                setOpenPanel={setOpenPanel}
                                exact_url={sub.exact_url ?? ""}
                                Icon={sub.icon ?? false}
                            />
                        ))}
                    </List>
                </Collapse>
            )}
        </>
    );
};

export const BUBBLES_DATA = () => {
    const { id: hotelID } = useSelector((state) => state.hotelID);

    return [
        {
            k: "dataBookings",
            t: "Bookings",
            reload: dataBookingsTypes.reload,
        },
        {
            k: "dataSimple",
            t: "Simple",
            reload: dataSimpleTypes.reload,
        },
        {
            k: "dataVouchersDetails",
            t: "Vouchers Details",
            reload: dataVouchersDetailsTypes.reload,
        },
        {
            k: "dataSearches",
            t: "Search Details",
            reload: dataSearchesTypes.reload,
        },
        ...(CheckShowBool("Membership", hotelID)
            ? [
                  {
                      k: "dataMembership",
                      t: "Membership",
                      reload: dataMembershipTypes.reload,
                  },
              ]
            : []),
        ...CheckShow({
            rolesThatCanSee: ARO_ADMIN,
            children: [
                {
                    k: "dataCommission",
                    t: "Commission",
                    reload: dataCommissionTypes.reload,
                },
            ],
            alternative: [],
        }),
    ];
};

const Bubbles = () => {
    const dispatch = useDispatch();
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Box sx={{ mr: 3 }} />
            {/*  _                 _ _             ___      _    _    _         */}
            {/* | |   ___  __ _ __| (_)_ _  __ _  | _ )_  _| |__| |__| |___ ___ */}
            {/* | |__/ _ \/ _` / _` | | ' \/ _` | | _ \ || | '_ \ '_ \ / -_|_-< */}
            {/* |____\___/\__,_\__,_|_|_||_\__, | |___/\_,_|_.__/_.__/_\___/__/ */}
            {/*                            |___/                                */}
            {BUBBLES_DATA().map(({ k, t, reload }, index) => {
                return (
                    <LoadingBubble
                        key={k}
                        dataKey={k}
                        title={t}
                        index={index}
                        reload={reload}
                    />
                );
            })}

            <IconButton
                size="small"
                onClick={() =>
                    BUBBLES_DATA().map(({ reload }) =>
                        dispatch({ type: reload })
                    )
                }
            >
                <Icons.Refresh
                    sx={{
                        fontSize: 18,
                    }}
                />
            </IconButton>
        </Box>
    );
};

export default SideMenu;
